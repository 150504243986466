import revive_payload_client_FVGfDBrJCn from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wfvqYv8Tyh from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Gl9pglnibK from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kmAZytc37l from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_e2KQn2Q6kP from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bz3LumdyUc from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_y04Tr7TJnf from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_bmzN1pKbFo from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.23.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_iERSApHd8U from "/overbookd/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_inpmvesoxjndlrw6pghepaidca/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  revive_payload_client_FVGfDBrJCn,
  unhead_wfvqYv8Tyh,
  router_Gl9pglnibK,
  payload_client_kmAZytc37l,
  navigation_repaint_client_e2KQn2Q6kP,
  check_outdated_build_client_bz3LumdyUc,
  chunk_reload_client_y04Tr7TJnf,
  plugin_vue3_bmzN1pKbFo,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iERSApHd8U,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]